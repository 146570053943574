import React, { useState } from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components';
import { Container, Row, Col, ResponsiveEmbed, Form, Button } from "react-bootstrap"
import { FaPaperPlane, } from 'react-icons/fa';


const Contact = () => {

  function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true)
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': "Prolacs_Contact",
          ...state,
        }),
      })
        .then(() => navigate(form.getAttribute('action')))
  }
  const { namesurname, position, comp, phone, email, checkboxChecked, message, } = useState({})
  const Map = styled(ResponsiveEmbed)`
height:15rem
`
  const SubscribeButton = styled(Button)`
background-color: #6efbc5;
margin-top:1rem;
width:10rem;
padding: .5rem;
color: #333333;
font-weight: 500;
border:none!important;
border-radius:unset;

&:hover
{
  background-color: #6efbc5;
  border:none;
}
&:active{
  border:none!important;
  background-color: #6efbc5!important;
}
@media (max-width: 600px) {
margin-top:1rem;
width:100%
   }   
  
  `
  return <Layout>
    <SEO title="Contact Us"
      description="Contact to Prolacs, ask us about our solutions, get information about our products. We enjoy our work and doing what is necessary." />
    <Container>
      <Row>
        <Col>
          <div className='prolacs-divider-title'>
            <br></br>
            <br></br>
            <h1>Contact Us</h1>
          </div>
          <Row>
            <Col sm>
              <p>Ask us about our solutions, get information about our products.
               <br /> We enjoy our work and doing what is necessary.
You can reach us from the contact information below.</p>
            </Col>
          </Row>
          <Row>

            <Col sm className='contact-us-col-2' >
              <div className='prolacs-about-us-row' style={{ width: 'auto', height: 'auto' }}>

              </div>
            </Col>


          </Row>
          <Form netlify noValidate validated={validated} onSubmit={handleSubmit} name="Prolacs_Contact" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field" >
            <Form.Group >
              <Form.Control required type="text" name="namesurname" value={namesurname} placeholder="Name" onChange={handleChange} />
            </Form.Group>
            <Form.Group >
              <Form.Control required type="email" name="email" value={email} placeholder="E-Mail" onChange={handleChange} />
              <Form.Control.Feedback type="invalid">Please type your e-mail</Form.Control.Feedback>
            </Form.Group>
            <Form.Group >
              <Form.Control  type="tel" pattern="[0-9]{3}[0-9]{3}[0-9]{4}" value={phone} name="phone" placeholder="Phone" onChange={handleChange} />
              <Form.Control.Feedback type="invalid">Please type your phone number</Form.Control.Feedback>
            </Form.Group>
            <Form.Group >
              <Form.Control type="text" name="comp" placeholder="Company" value={comp} onChange={handleChange} />
            </Form.Group>
            <Form.Group >
              <Form.Control type="text" name="position" value={position} placeholder="Title" onChange={handleChange} >
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Control className='form-message-field' value={message} required as="textarea" name="message" rows="5" placeholder="Your Message" onChange={handleChange} />
            </Form.Group>
            <Form.Check

              onChange={handleChange}
              checked={checkboxChecked}
              value={checkboxChecked ? 'No' : 'YES'}
              name="subscribe"
              custom
              type='checkbox'
              id={`custom-${'checkbox'}`}
              label="I want to receive latest news about Laundry 4.0 from Prolacs"
            />
   
              <SubscribeButton type='submit'> Send <FaPaperPlane /></SubscribeButton>
 
          </Form>
        </Col>
        <Map aspect="a16by9">
          <embed src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12491.556965395408!2d27.0341538!3d38.4901976!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x620abbcacc1a496d!2sProlacs!5e0!3m2!1str!2str!4v1562675414933!5m2!1str!2str" />
        </Map>
      </Row>
    </Container>
  </Layout>

}

export default Contact
